










import { Component, Vue } from "vue-property-decorator";
import PageFrame from "@/components/elements/PageFrame.vue";
import PricePlan from "@/components/elements/PricePlan.vue";

/**
 * Renders the pricing page
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { PricePlan, PageFrame }
})
export default class Pricing extends Vue {



}
