




































































import { Component, Vue } from "vue-property-decorator";
import RegisterDialog from "@/components/dialogs/RegisterDialog.vue";

/**
 * Handles the priceplan interactions
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { RegisterPopup: RegisterDialog }
})
export default class PricePlan extends Vue {
  /**
   * Returns the visibility state of the register popup
   * 
   * @var registerPopupOpen
   * @type boolean
   */
  private registerPopupOpen = false;

  /**
   * Closes the register popup
   * 
   * @returns void
   */
  registerPopupClosed() {
    this.registerPopupOpen = false;
  }

  /**
   * Opens the register popup
   * 
   * @returns void
   */
  registerPopupOpened() {
    this.registerPopupOpen = true;
  }

}
